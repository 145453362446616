<template>
    <v-container grid-list-md text-xs-center fluid>
        <h1>Carregadores</h1>
        <v-divider class="mb-4" />
        <v-layout wrap v-if="chargers.length > 0">
            <v-flex xs12 md6 offset-md6 v-if="chargers.length > 10">
                <v-text-field label="Buscar" v-model="filtro" />
            </v-flex>
        </v-layout>

        <div v-for="charger of listWithFilter" :key="charger.UUID">
            <ChargerClientView :charge="charger" />
        </div>
    </v-container>
</template>

<script>
import ChargerClientView from "./ChargerClientView";

export default {
    components: {
        ChargerClientView,
    },

    data() {
        return {
            timer: null,
            filtro: "",
            chargers: [],
        };
    },

    computed: {
        listWithFilter() {
            if (this.filtro) {
                let exp = new RegExp(this.filtro.trim(), "i");
                return this.chargers.filter((charge) => exp.test(charge.UUID) || exp.test(charge.name) || (charge.point && exp.test(charge.point.name)));
            } else {
                return this.chargers;
            }
        },
    },

    created() {
        this.update();
        this.timer = setInterval(this.update, 5000);
    },

    methods: {
        update() {
            this.$http
                .get("api/v2/bike/charger/" + this.$store.state.user.ID, { continuous: true })
                .then((result) => {
                    this.chargers = result;
                })
                .catch(() => {
                    this.chargers = [];
                });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
